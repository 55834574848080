import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, graphql, Link } from "gatsby"

import { rhythm } from "../utils/typography"

export default ({ children }) => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    )
    return (
    <div
        css={css`
            margin: 0 auto;
            max-width: 700px;
            padding: ${rhythm(2)};
            padding-top: ${rhythm(1.5)};
        `}
    >
        {children}
    </div>
    )
}