import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default({ data }) => (
    <Layout>
        <h1>{data.site.siteMetadata.title}</h1>
        <p>Zak's message to Abram</p>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/CdUOGusKMec" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </Layout>
)

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`