import Typography from "typography"

const typography = new Typography({
    baseLineHeight: 1.666,
    headerFontFamily: ['Roboto', 'Helvetica Neue', 'Segoe UI', 'Helvetica', 'Arial', 'sans-serif'],
    headerWeight: 300,
    headerColor: '#565656',
    bodyFontFamily: ['Georgia', 'serif'],
  }
)

export const { scale, rhythm, options } = typography
export default typography